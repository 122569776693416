import React, { useRef } from "react";
import { Cookies } from "react-cookie";
import { Div, FlexRow, Spacer } from "../../../core/Containers";
import { Text } from "../../../core/Text";
import { ColumnHeader } from "../../../components/Tables";
import { Pagination } from "../../../components/Tables/Pagination";
import { useNavigate } from "react-router-dom";
import { RawButton, RawDropdown } from "../../../core/Buttons";
import { ImSpinner2 } from "react-icons/im";
import { LBCModal } from "../../../components/Modals";
import moment from "moment";
import { TrackingHistoryModal } from "../../../components/Modals/TrackingHistoryModal";
import axiosCAPApiPrivate from "../../../api/axiosCAPApiPrivate"; 
import axiosLexaApiPrivate from "../../../api/axiosLexaApiPrivate";

const cookies = new Cookies();

export const TrackAndTraceTab = ({ searchTriggrerd, setSearchTriggrerd }) => {
  const navigate = useNavigate();
  const dataFetchedRef = useRef(false);

  const [shipperAcctName, setShipperAcctName] = React.useState("");
  const [shipperAcctNo, setShipperAcctNo] = React.useState("");
  const [trackingNos, setTrackingNos] = React.useState("");

  const [dsMultipleTrackingNoData, setDSMultipleTrackingNoData] =
    React.useState([]);

  const [loading, setLoading] = React.useState(true);
  const [isAscending, setAscending] = React.useState(true);
  const [sortList, setSortList] = React.useState([]);

  const [singleView, setSingleView] = React.useState("default");
  const [selected, setSelected] = React.useState("default");
  const [selectedList, setSelectedList] = React.useState([]);

  const [currentPage, setCurrentPage] = React.useState(0);
  const [articlesPerPage] = React.useState(10);
  const indexOfLastArticle = currentPage * articlesPerPage;
  const indexOfFirstArticle = indexOfLastArticle - articlesPerPage;

  const newItems = sortList.slice(indexOfFirstArticle, indexOfLastArticle);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const [isToastOpen, setToastOpen] = React.useState(false);
  const [toastKind, setToastKind] = React.useState("");
  const [toastTitle, setToastTitle] = React.useState("");
  const [toastDesc, setToastDesc] = React.useState("");

  const [selectedData, setSelectedData] = React.useState(null);
  const [isTHistoryOpen, setIsTHistoryOpen] = React.useState(false);

  React.useEffect(() => {
    if (dataFetchedRef.current) return;
    dataFetchedRef.current = true;

    trackNTraceSeach();
  }, []);

  React.useEffect(() => {
    if (searchTriggrerd) {
      trackNTraceSeach();
      setSearchTriggrerd(false);
    }
  }, [searchTriggrerd]);

  const trackNTraceSeach = async () => {
    setDSMultipleTrackingNoData([]);
    setLoading(true);

    var _shipperAcct = cookies.get("shipperAcct", { path: "/" });
    var _shipperAcctSplit = _shipperAcct ? _shipperAcct.split("|") : [];
    var _shipperAcctName = _shipperAcctSplit[0]
      ? _shipperAcctSplit[0].toString().trim()
      : "";
    var _shipperAcctNo = _shipperAcctSplit[1]
      ? _shipperAcctSplit[1].toString().trim()
      : "";

    setShipperAcctName(_shipperAcctName);
    setShipperAcctNo(_shipperAcctNo);

    var _trackingNos = cookies.get("trackingnos", { path: "/" }) || "";
    setTrackingNos(_trackingNos);

    const userInfo = cookies.get("userInfo", { path: "/" });

    const reqData = {
      customerAccountNo: _shipperAcctNo,
      trackingNos: _trackingNos,
      isCAMS: userInfo?.isCAMS
    };

    try {
      const response = await axiosCAPApiPrivate.post(
        "/api/TrackNTraceSeach",
        reqData
      );
      const resData = response.data;

      setDSMultipleTrackingNoData(resData.Data || []);
      setCurrentPage(resData?.Data?.length ? 1 : 0);
      setSortList(resData.Data || []);
      setLoading(false);
      cookies.remove("trackingnos");
    } catch (error) {
      setToastKind("error");
      setToastTitle("API Fetch Failed!");
      setToastDesc(error.message);
      setTimeout(() => {
        setLoading(false);
      }, 1000);
      setTimeout(() => {
        setToastOpen(true);
      }, 1500);
      setTimeout(() => {
        setToastOpen(false);
      }, 3000);
      cookies.remove("trackingnos");
    }
  };

  const onSort = async (column) => {
    setLoading(true);
    if (isAscending) {
      if (column === 0) {
        const tempList = await sortList.sort((a, b) => {
          if (a.trackingNo < b.trackingNo) {
            return -1;
          }
          if (a.trackingNo > b.trackingNo) {
            return 1;
          }
          return 0;
        });
        setSortList(tempList);
      } else if (column === 1) {
        const tempList = await sortList.sort((a, b) => {
          if (a.transactionDate < b.transactionDate) {
            return -1;
          }
          if (a.transactionDate > b.transactionDate) {
            return 1;
          }
          return 0;
        });
        setSortList(tempList);
      } else if (column === 2) {
        const tempList = await sortList.sort((a, b) => {
          if (a.shipper < b.shipper) {
            return -1;
          }
          if (a.shipper > b.shipper) {
            return 1;
          }
          return 0;
        });
        setSortList(tempList);
      } else if (column === 3) {
        const tempList = await sortList.sort((a, b) => {
          if (a.consignee < b.consignee) {
            return -1;
          }
          if (a.consignee > b.consignee) {
            return 1;
          }
          return 0;
        });
        setSortList(tempList);
      }
      setTimeout(() => {
        setLoading(false);
        setAscending(!isAscending);
      }, 1000);
    } else {
      if (column === 0) {
        const tempList = await sortList.sort((a, b) => {
          if (a.trackingNo > b.trackingNo) {
            return -1;
          }
          if (a.trackingNo < b.trackingNo) {
            return 1;
          }
          return 0;
        });
        setSortList(tempList);
      } else if (column === 1) {
        const tempList = await sortList.sort((a, b) => {
          if (a.transactionDate > b.transactionDate) {
            return -1;
          }
          if (a.transactionDate < b.transactionDate) {
            return 1;
          }
          return 0;
        });
        setSortList(tempList);
      } else if (column === 2) {
        const tempList = await sortList.sort((a, b) => {
          if (a.shipper > b.shipper) {
            return -1;
          }
          if (a.shipper < b.shipper) {
            return 1;
          }
          return 0;
        });
        setSortList(tempList);
      } else if (column === 3) {
        const tempList = await sortList.sort((a, b) => {
          if (a.consignee > b.consignee) {
            return -1;
          }
          if (a.consignee < b.consignee) {
            return 1;
          }
          return 0;
        });
        setSortList(tempList);
      }
      setTimeout(() => {
        setLoading(false);
        setAscending(!isAscending);
      }, 1000);
    }
  };

  const onShowTracking = (data) => {
    setSelectedData(data);
    setIsTHistoryOpen(true);
  };

  return (
    <>
      <LBCModal
        id="alertModal"
        description={toastDesc}
        isOpen={isToastOpen}
        onClose={() => setToastOpen(false)}
        title={toastTitle}
        toastKind={toastKind}
      />

      {isTHistoryOpen && (
        <TrackingHistoryModal
          isOpen={isTHistoryOpen}
          onClose={() => setIsTHistoryOpen(false)}
          data={selectedData}
        />
      )}

      <Div className="w-full ">
        <Spacer className="w-full h-10" />
        <Div className="w-full border border-grey-400 rounded-lg shadow-lg">
          <FlexRow className="items-center justify-between py-2 border border-grey-400">
            <ColumnHeader
              title="Tracking No."
              onClick={() => onSort(0)}
              containerClass="hover:bg-grey-400 rounded-full w-1/6 px-4 py-2"
              titleClassName=""
            />
            <ColumnHeader
              title="Transaction Date"
              onClick={() => onSort(1)}
              containerClass="hover:bg-grey-400 rounded-full w-1/6 px-4 py-2"
              titleClassName=""
            />
            <ColumnHeader
              title="Sender"
              onClick={() => onSort(2)}
              containerClass="hover:bg-grey-400 rounded-full w-1/6 px-4 py-2"
              titleClassName=""
            />
            <ColumnHeader
              title="Receiver"
              onClick={() => onSort(3)}
              containerClass="hover:bg-grey-400 rounded-full w-1/6 px-4 py-2"
              titleClassName=""
            />
            <Text className="text-secondary-100 text-xs text-center font-semibold w-1/6 px-4 py-2">
              Last Status
            </Text>
            <Text className="text-secondary-100 text-xs text-center font-semibold w-1/6 px-4 py-2">
              Status Date
            </Text>
          </FlexRow>
          {loading ? (
            <Text className="text-red-400 text-center flex flex-row justify-center items-center my-20">
              <ImSpinner2 className="animate-spin mr-2 text-2xl desktop:text-3xl" />
              Loading data ...
            </Text>
          ) : (
            <Div className="w-full">
              {newItems.reverse().map((item, i) => (
                <FlexRow
                  className="items-center justify-between space-x-2 w-full py-2 border-l-8 border-l-white hover:border-l-red-400 hover:bg-grey-400"
                  key={i}
                >
                  <RawButton
                    className="w-1/6"
                    onClick={() => onShowTracking(item)}
                  >
                    <Text className="text-secondary-100 text-sm px-4 hover:text-red-400 hover:underline">
                      {item.trackingNo}
                    </Text>
                  </RawButton>
                  <Text className="text-secondary-100 text-sm text-left w-1/6">
                    {item.transactionDate !== ""
                      ? moment(item.transactionDate).format("MMM D, yyyy")
                      : ""}
                  </Text>
                  <Text className="text-secondary-100 text-sm text-left w-1/6">
                    {item.shipperName}
                  </Text>
                  <Text className="text-secondary-100 text-sm text-left w-1/6">
                    {item.consigneeName}
                  </Text>
                  <Text className="text-secondary-100 text-sm text-left w-1/6">
                    {item.lastStatus}
                  </Text>
                  <Text className="text-secondary-100 text-sm text-left w-1/6">
                    {item.datePostedLast !== ""
                      ? moment(item.datePostedLast).format("MMM D, yyyy")
                      : ""}
                  </Text>
                </FlexRow>
              ))}
            </Div>
          )}
        </Div>
        <Spacer className="w-full h-10" />
        <Div className="w-full">
          <Pagination
            currentPage={currentPage}
            itemsPerPage={articlesPerPage}
            paginate={paginate}
            totalItems={dsMultipleTrackingNoData.length}
          />
        </Div>
      </Div>
    </>
  );
};
