import { Div, FlexColumn, FlexRow, Spacer } from "../../core/Containers";
import { Text } from "../../core/Text";
import { Image } from "../../core/Image";
import  BookNowComingSoon  from "../Modals/BookNowComingSoon";
import { ImHome, ImUser } from "react-icons/im";
import { IoMdApps } from "react-icons/io";
import { AiFillBook, AiFillFileText, AiOutlineStop } from "react-icons/ai";
import { CgCalendarDates } from "react-icons/cg";
import {
  FaTruck,
  FaQuestionCircle,
  FaWallet,
  FaAddressBook,
} from "react-icons/fa";
import { Link } from "react-router-dom";
import {
  BookNowOptions,
  ToolsOptions,
  ActivityOptions,
} from "../../constants/Dropdowns";
import { Images } from "../../assets/images/images";
import { MdLocationOn } from "react-icons/md";
import { BsBook, BsFillBoxSeamFill, BsFillHandbagFill } from "react-icons/bs";
import { GoCalendar } from "react-icons/go";
import { Cookies } from "react-cookie";
import { useState } from "react";
import { useNavigate } from 'react-router-dom';

const cookies = new Cookies();

export const NavbarMain = ({ route, onBookNowClick }) => {
  
  const [isModalOpen, setIsModalOpen] = useState(false);
  const userInfo = cookies.get("userInfo", { path: "/" }) || {};
  const isCAMS = userInfo?.isCAMS ?? false;
  const navigate = useNavigate();

  const handleBookNowClick = (e, link) => {
    if (isCAMS) {
      e.preventDefault(); // Prevent the default navigation behavior
      setIsModalOpen(true); // Open the modal
    } else {
      // If not CAMS, proceed with navigation manually
      navigate(link.url);  // Use navigate() for manual redirection
      onBookNowClick(link.tabIndex); // Optional: Proceed with normal action
    }
  };

  const getIcon = (name) => {
    if (name === "Address Book") {
      return <FaAddressBook />;
    } else if (name === "Nearby Branches") {
      return <MdLocationOn />;
    } else if (name === "Prohibited Items") {
      return <AiOutlineStop />;
    } else if (name === "Delivery Zones") {
      return <BsBook />;
    } else if (name === "Delivery Lead Time") {
      return <GoCalendar />;
    } else if (name === "Customer Support") {
      return <ImUser />;
    } else if (name === "Terms & Conditions") {
      return <AiFillFileText />;
    } else if (name === "Products & Services") {
      return <BsFillBoxSeamFill />;
    } else if (name === "Send Tipid") {
      return <BsFillHandbagFill />;
    } else {
      return <FaAddressBook />;
    }

  };

  // const userInfo = cookies.get("userInfo", { path: "/" });
  

  const handleCloseModal = () => {
    setIsModalOpen(false);
  }

  return (
    <FlexColumn className="bg-red-400 h-full w-1/12 py-2 laptopSm:py-5 items-center justify-between">
      <Div>
        <FlexColumn className="mx-auto justify-center items-center laptopSm:w-24">
          <Image className="h-14 w-14" alt="LBC logo" src={Images.LBClogo} />
          {userInfo && userInfo.accountTypeTag === 1 && (
            <>
              <Text className="text-white text-xs text-center">BUSINESS</Text>
              <Text className="text-white text-xs text-center">SOLUTIONS</Text>
            </>
          )}
        </FlexColumn>

        <Spacer className="border border-white bg-white my-2" />

        <Link to="/dashboard-home">
          <FlexColumn
            className={`${
              route === "Home" ? "text-white" : "text-secondary-200"
            } mx-auto py-2 laptopSm:py-5 justify-center items-center laptopSm:w-24 rounded-lg hover:bg-red-100 hover:bg-opacity-50 hover:text-white`}
          >
            <ImHome className={`laptopSm:text-2xl`} />
            <Text className={`text-xs text-center`}>Home</Text>
          </FlexColumn>
        </Link>

        {(userInfo?.accessModules?.includes("bookNow") ||
          userInfo?.accessModules?.includes("bookNowPUpSched") ||
          userInfo?.accessModules?.includes("bookNowReqSupplies")) && (
          <Link to={isCAMS ? "#" : "/book-now"}> {/* Pag isCAMS, # lang para hindi mag redirect */}
            <FlexColumn
              className={`${
                route === "Book Now" ? "text-white" : "text-secondary-200"
              } mx-auto py-2 laptopSm:py-5 justify-center items-center laptopSm:w-24 rounded-lg 
                ${isCAMS ? "cursor-not-allowed text-grey-400" : "hover:bg-red-100 hover:bg-opacity-50 hover:text-white"} parentToolTip`}
            >
              <AiFillBook className={`laptopSm:text-2xl`} />
              <Text className={`text-xs text-center`}>
                {isCAMS ? "Book Now (Coming Soon)" : "Book Now"}
              </Text>

              {/* Disable the childTooltip and any child links if isCAMS */}
              {!isCAMS && (
                <Div className="childTooltip left-full pl-5 z-10">
                  <Div className="w-48 p-2 laptopSm:p-5 rounded-lg shadow-lg bg-white border border-grey-400">
                    {BookNowOptions.map((link, i) => {
                      if (userInfo?.accessModules?.includes(link.id)) {
                        if (route === "Book Now") {
                          return (
                            <Link
                              className="block text-secondary-100 text-xs laptopSm:text-base hover:text-red-400 hover:font-bold hover:bg-grey-400 p-2"
                              to=""
                              key={i}
                              onClick={() => onBookNowClick(link.tabIndex)} // Call the function with link.tabIndex
                            >
                              {link.name}
                            </Link>
                          );
                        } else {
                          return (
                            <Link
                              className="block text-secondary-100 text-xs laptopSm:text-base hover:text-red-400 hover:font-bold hover:bg-grey-400 p-2"
                              to={link.url}
                              key={i}
                            >
                              {link.name}
                            </Link>
                          );
                        }
                      }
                    })}
                  </Div>
                </Div>
              )}
            </FlexColumn>
          </Link>
        )}

        {/* {(userInfo?.accessModules?.includes("bookNow") ||
          userInfo?.accessModules?.includes("bookNowPUpSched") ||
          userInfo?.accessModules?.includes("bookNowReqSupplies")) && (
          <Link to="/book-now" onClick={handleBookNowClick}>
            <FlexColumn
              className={`${
                route === "Book Now" ? "text-white" : "text-secondary-200"
              } mx-auto py-2 laptopSm:py-5 justify-center items-center laptopSm:w-24 rounded-lg hover:bg-red-100 hover:bg-opacity-50 hover:text-white parentToolTip`}
            >
              <AiFillBook className={`laptopSm:text-2xl`} />
              <Text className={`text-xs text-center`}>
                Book Now
              </Text>

              <Div className="childTooltip left-full pl-5 z-10">
                <Div className="w-48 p-2 laptopSm:p-5 rounded-lg shadow-lg bg-white border border-grey-400">
                  {BookNowOptions.map(
                    (link, i) =>
                      userInfo?.accessModules?.includes(link.id) &&
                      (route === "Book Now" ? (
                        <Link
                          className="block text-secondary-100 text-xs laptopSm:text-base hover:text-red-400 hover:font-bold hover:bg-grey-400 p-2"
                          to=""
                          key={i}
                          onClick={() => onBookNowClick(link.tabIndex)}
                        >
                          {link.name}
                        </Link>
                      ) : (
                        <Link
                          className="block text-secondary-100 text-xs laptopSm:text-base hover:text-red-400 hover:font-bold hover:bg-grey-400 p-2"
                          to={link.url}
                          key={i}
                        >
                          {link.name}
                        </Link>
                      ))
                  )}
                </Div>
              </Div>
            </FlexColumn>
          </Link>
        )}

        {isModalOpen && (
          <BookNowComingSoon
            isOpen={isModalOpen}
            onClose = {handleCloseModal}
          />
        )} */}

        {/* <Link to="/activity">
          <FlexColumn
            className={`${
              route === "Activity" ? "text-white" : "text-secondary-200"
            } mx-auto py-2 laptopSm:py-5 justify-center items-center laptopSm:w-24 rounded-lg hover:bg-red-100 hover:bg-opacity-50 hover:text-white`}
          >
            <CgCalendarDates className={`laptopSm:text-2xl`} />
            <Text className={`text-xs text-center`}>Activity</Text>
          </FlexColumn>
        </Link> */}

        {(userInfo?.accessModules?.includes("activityOLBookings") ||
          userInfo?.accessModules?.includes("activityAllTrans")) && (
          <Link to="#">
            <FlexColumn
              className={`${
                route === "Activity" ? "text-white" : "text-secondary-200"
              } mx-auto py-2 laptopSm:py-5 justify-center items-center laptopSm:w-24 rounded-lg hover:bg-red-100 hover:bg-opacity-50 hover:text-white parentToolTip`}
            >
              <CgCalendarDates className={`laptopSm:text-2xl`} />
              <Text className={`text-xs text-center`}>Activity</Text>

              <Div className="childTooltip left-full pl-5 z-10">
                <Div className="w-48 p-2 laptopSm:p-5 rounded-lg shadow-lg bg-white border border-grey-400">
                  {ActivityOptions.map(
                    (link, i) =>
                      userInfo?.accessModules?.includes(link.id) && (
                        <Link
                          className="block text-secondary-100 text-xs laptopSm:text-base hover:text-red-400 hover:font-bold hover:bg-grey-400 p-2"
                          to={link.url}
                          key={i}
                        >
                          {link.name}
                        </Link>
                      )
                  )}
                </Div>
              </Div>
            </FlexColumn>
          </Link>
        )}

        {(userInfo?.accessModules?.includes("reportsStatReport") ||
          userInfo?.accessModules?.includes("reportsdelReport") ||
          userInfo?.accessModules?.includes("reportsRemitReport") ||
          userInfo?.accessModules?.includes("reportsPOD") ||
          userInfo?.accessModules?.includes("reportsRemitHistory")) && (
          <Link to="/reports">
            <FlexColumn
              className={`${
                route === "Reports" ? "text-white" : "text-secondary-200"
              } mx-auto py-2 laptopSm:py-5 justify-center items-center laptopSm:w-24 rounded-lg hover:bg-red-100 hover:bg-opacity-50 hover:text-white`}
            >
              <FaTruck className={`laptopSm:text-2xl`} />
              <Text className={`text-xs text-center`}>Reports</Text>
            </FlexColumn>
          </Link>
        )}

        {/* <Link to="/payments">
          <FlexColumn
            className={`${route === "Payments" ? "text-white" : "text-secondary-200"
              } mx-auto py-2 laptopSm:py-5 justify-center items-center laptopSm:w-24 rounded-lg hover:bg-red-100 hover:bg-opacity-50 hover:text-white`}
          >
            <FaWallet className={`laptopSm:text-2xl`} />
            <Text className={`text-xs text-center`}>Payments</Text>
          </FlexColumn>
        </Link> */}
      </Div>

      {/* <Div>
        <FlexColumn
          className={`text-white mx-auto py-2 laptopSm:py-5 justify-center items-center laptopSm:w-24 rounded-lg hover:bg-red-100 hover:bg-opacity-50 hover:text-white parentToolTip`}
        >
          <IoMdApps className={`laptopSm:text-2xl`} />
          <Text className={`text-xs text-center`}>Tools</Text>

          <Div className="childTooltip left-full bottom-0 pl-5 z-10">
            <Div className="w-300px h-300px p-2 laptopSm:px-5 rounded-lg shadow-lg bg-white border border-grey-400 pt-8">
              <FlexRow className="items-start justify-between w-full">
                {ToolsOptions.tools1.map((link, i) => (
                  <Link
                    className="w-20 block text-secondary-100 text-xs text-center rounded-ld hover:text-red-400 hover:bg-red-70 p-1"
                    to={link.url}
                    key={i}
                  >
                    <Div className="flex items-center justify-center w-8 h-8 rounded-md bg-grey-300 mb-1 mx-auto text-red-400 text-sm">
                      {getIcon(link.name)}
                    </Div>
                    {link.name}
                  </Link>
                ))}
              </FlexRow>
              <FlexRow className="items-start justify-between w-full">
                {ToolsOptions.tools2.map((link, i) => (
                  <Link
                    className="w-20 block text-secondary-100 text-xs text-center rounded-ld hover:text-red-400 hover:bg-red-70 p-1"
                    to={link.url}
                    key={i}
                  >
                    <Div className="flex items-center justify-center w-8 h-8 rounded-md bg-grey-300 mb-1 mx-auto text-red-400 text-sm">
                      {getIcon(link.name)}
                    </Div>
                    {link.name}
                  </Link>
                ))}
              </FlexRow>
              <FlexRow className="items-start justify-between w-full">
                {ToolsOptions.tools3.map((link, i) => (
                  <Link
                    className="w-20 block text-secondary-100 text-xs text-center rounded-ld hover:text-red-400 hover:bg-red-70 p-1 m-1"
                    to={link.url}
                    key={i}
                  >
                    <Div className="flex items-center justify-center w-8 h-8 rounded-md bg-grey-300 mb-1 mx-auto text-red-400 text-sm">
                      {getIcon(link.name)}
                    </Div>
                    {link.name}
                  </Link>
                ))}
              </FlexRow>
            </Div>
          </Div>
        </FlexColumn>
        <FlexColumn
          className={`text-white mx-auto py-2 laptopSm:py-5 justify-center items-center laptopSm:w-24 rounded-lg hover:bg-red-100 hover:bg-opacity-50 hover:text-white`}
        >
          <FaQuestionCircle className={`laptopSm:text-2xl`} />
          <Text className={`text-xs text-center`}>Help</Text>
        </FlexColumn>
      </Div> */}
    </FlexColumn>
  );
};
